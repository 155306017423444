import ApiService from "@/core/services/ApiService";

const ADD_DOCUMENT_URL = "documentation/add-document/";
const EDIT_DOCUMENT_URL = "documentation/update-document/";
const DELETE_DOCUMENT_URL = "documentation/delete-document/";

const DELETE_CATEGORY_URL = "documentation/delete-category/";

const GET_USER_DOCUMENTATION_URL = 'documentation/get-documentation';
const GET_DOCUMENT_URL = 'documentation/get-document/';

export default {
    addDocument(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_DOCUMENT_URL, payload);
    },
    getDocument(document_id) {
        ApiService.setHeader();
        return ApiService.get(GET_DOCUMENT_URL + document_id).then((response) => {
            return response.data.data;
        });
    },
    editDocument(document_id, payload) {
        ApiService.setHeader();
        return ApiService.post(EDIT_DOCUMENT_URL + document_id + "/", payload);
    },
    deleteDocument(document_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_DOCUMENT_URL + document_id + "/");
    },
    deleteCategory(document_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CATEGORY_URL + document_id + "/");
    },
    getUserDocumentation() {
        ApiService.setHeader();
        return ApiService.get(GET_USER_DOCUMENTATION_URL).then((response) => {
            return response.data.data;
        });
    },
};
